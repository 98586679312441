import { Button, Checkbox, Drawer, Popover, Space } from 'antd';
import CustomDatePicker from 'components/DatePicker';
import SearchBar from 'components/Search';
import CustomTable from 'components/Table'
import Toolbar from 'components/Toolbar'
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ASSIGNTASK, ASSIGNTASKSUCCESS, GETPROJECTRESPONDENTS, GETPROJECTTASKS } from 'state/actions';
// import constants from 'utils/constants';

function AssignList() {

  const dispatch = useDispatch();
  const { selProject } = useSelector(state => state.user)
  const { projectRespondents, tasks, assignTaskStatus } = useSelector(state => state.task);
  const [userData, setUserData] = useState([]);
  const [taskSet, setTaskSet] = useState([]);
  const [columnSet, setColumnSet] = useState([]);
  const [selRespondents, setSelRespondents] = useState([]);
  const [seltaskSet, setSelTaskSet] = useState([]);
  const [seltasks, setSeltasks] = useState([]);
  const [taskDetails, selTaskDetails] = useState("");
  const [taskLimit, setTaskLimit] = useState(0);
  const [open, setOpen] = useState(false)

  const columns = [
    {
      title: 'All Respondents',
      dataIndex: 'full_name',
      width: "600px",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: 'Initials',
      dataIndex: 'initials',
    },
    {
      title: 'UUID',
      dataIndex: 'respondent_uuid',
    }
  ]


  useEffect(() => {
    setUserData(projectRespondents)
    if (assignTaskStatus) {
      handleDone()
      dispatch(ASSIGNTASKSUCCESS(false))
    } else {
      setColumnSet(columns)
    }
  }, [projectRespondents])

  useEffect(() => {
    if (selProject?.uuid) {
      dispatch(GETPROJECTRESPONDENTS({ project_uuid: selProject.uuid }))
      dispatch(GETPROJECTTASKS(selProject.uuid))
    }
  }, [selProject])

  useEffect(() => {
    if (selProject?.uuid && assignTaskStatus) {
      console.log("Refresh assign table");
      dispatch(GETPROJECTRESPONDENTS({ project_uuid: selProject.uuid }))
    }
  }, [assignTaskStatus])

  useEffect(() => {
    setTaskSet(tasks);
  }, [tasks])

  const onClose = () => {
    setOpen(false);
  };

  const handleChange = (e, name) => {

    setSelTaskSet({ ...seltaskSet, [name.uuid]: e.target.checked })

    console.log("seltasks", seltasks);
    let taskList = seltasks;
    if (e.target.checked) {
      taskList.push(name.uuid)
    } else {
      delete taskList[name.uuid]
    }

    setSeltasks(taskList);
    // onChange(e.target.checked, name)
  }

  const onSearch = (e) => {
    // console.log(info?.source, value)
    console.log(e.target.value)
    // props.setFiltrersData({ ...filters, 'search_query' : e.target.value})
  }

  const checkIfDisabled = (uuid) => {
    if (!seltaskSet[uuid] && taskLimit > 2) {
      return true
    }
    return false
  }

  useEffect(() => {
    const valueSet = Object.values(seltaskSet);
    let counter = 0;
    valueSet.map(val => {
      if (val) {
        counter++;
      }
    })

    setTaskLimit(counter);

  }, [seltaskSet])

  const HandleAssignTask = (record, task_uuid) => {

    let payloadTemp = [];
    const utcDate = moment.utc(moment().utc()).format();

    const taskAssignDetails = {
      "task_uuid": task_uuid,
      "respondenets": [
        {
          "respondenet_uuid": [record.respondent_uuid],
          "assigned": true,
          "scheduled_date": utcDate
        }
      ]
    };

    payloadTemp.push(taskAssignDetails);

    const payload = {
      project_uuid: selProject.uuid,
      assigntasks: payloadTemp,
    }

    dispatch(ASSIGNTASK(payload))
  }

  const HandleUnssignTask = (record, task_uuid) => {

    let payloadTemp = [];

    const taskAssignDetails = {
      "task_uuid": task_uuid,
      "respondenets": [
        {
          "respondenet_uuid": [record.respondent_uuid],
          "assigned": false
        }
      ]
    };

    payloadTemp.push(taskAssignDetails);

    const payload = {
      project_uuid: selProject.uuid,
      assigntasks: payloadTemp
    }

    dispatch(ASSIGNTASK(payload))
  }

  const HandleScheduleTask = (record, task_uuid, date) => {

    let payloadTemp = [];

    const taskAssignDetails = {
      "task_uuid": task_uuid,
      "respondenets": [
        {
          "respondenet_uuid": [record.respondent_uuid],
          "assigned": true,
          "scheduled_date": date
        }
      ]
    };

    payloadTemp.push(taskAssignDetails);

    const payload = {
      project_uuid: selProject.uuid,
      assigntasks: payloadTemp
    }

    dispatch(ASSIGNTASK(payload))
  }

  const HandleAssignTaskAll = (record, task_uuid) => {

    const utcDate = moment.utc(moment().utc()).format();

    const payloadTemp = {
      "task_uuid": task_uuid,
      "respondenets": [
        {
          "assigned": true,
          "isSelected": "all",
          "scheduled_date": utcDate
        }
      ]
    };

    const payload = {
      project_uuid: selProject.uuid,
      assigntasks: [payloadTemp]
    }

    dispatch(ASSIGNTASK(payload))

  }

  const HandleUnassignTaskAll = (record, task_uuid) => {
    const payloadTemp = {
      "task_uuid": task_uuid,
      "respondenets": [
        {
          "assigned": false,
          "isSelected": "all"
        }
      ]
    };

    const payload = {
      project_uuid: selProject.uuid,
      assigntasks: [payloadTemp]
    }

    dispatch(ASSIGNTASK(payload))

  }

  const HandleScheduleTaskAll = (sDate, task_uuid) => {
    const payloadTemp = {
      "task_uuid": task_uuid,
      "respondenets": [
        {
          "assigned": true,
          "isSelected": "all",
          "scheduled_date": sDate
        }
      ]
    };

    const payload = {
      project_uuid: selProject.uuid,
      assigntasks: [payloadTemp]
    }

    dispatch(ASSIGNTASK(payload))

  }

  const HandleAssignTaskSelected = (record, task_uuid) => {

    const respoSet = [];
    selRespondents[1].map(r => {
      respoSet.push(r.respondent_uuid)
    });

    const utcDate = moment.utc(moment().utc()).format();

    const payloadTemp = {
      "task_uuid": task_uuid,
      "respondenets": [
        {
          "assigned": true,
          "respondenet_uuid": respoSet,
          "scheduled_date": utcDate
        }
      ]
    };

    const payload = {
      project_uuid: selProject.uuid,
      assigntasks: [payloadTemp]
    }

    dispatch(ASSIGNTASK(payload))

  }

  const HandleUnassignTaskSelected = (record, task_uuid) => {

    const respoSet = [];
    selRespondents[1].map(r => {
      respoSet.push(r.respondent_uuid)
    });

    const payloadTemp = {
      "task_uuid": task_uuid,
      "respondenets": [
        {
          "assigned": false,
          "respondenet_uuid": respoSet
        }
      ]
    };

    const payload = {
      project_uuid: selProject.uuid,
      assigntasks: [payloadTemp]
    }

    dispatch(ASSIGNTASK(payload))

  }

  const HandleScheduleTaskSelected = (sDate, task_uuid) => {

    const respoSet = [];
    selRespondents[1].map(r => {
      respoSet.push(r.respondent_uuid)
    });


    const payloadTemp = {
      "task_uuid": task_uuid,
      "respondenets": [
        {
          "assigned": true,
          "respondenet_uuid": respoSet,
          "scheduled_date": sDate
        }
      ]
    };

    const payload = {
      project_uuid: selProject.uuid,
      assigntasks: [payloadTemp]
    }

    dispatch(ASSIGNTASK(payload))

  }

  const checkIfTaskExit = (r, taskId) => {

    const fTask = r.tasks.filter(t => t.task_uuid == taskId);
    return fTask.length ? fTask : false;

  }

  const checkIfAllTaskAssigned = (r, taskId) => {
    let isAssigned = [];
    if (selRespondents.length && selRespondents[1].length) {
      // projectRespondents.map(respondent => {
      selRespondents[1].map(respondent => {
        let tempRespondent = respondent;
        projectRespondents.map(resp => {
          if(resp.respondent_uuid == respondent.respondent_uuid ) {
            tempRespondent.tasks = resp.tasks
          }
        })

        const filterTask = tempRespondent.tasks.filter(t => t.task_uuid == taskId)
        if (!!filterTask.length) {
          isAssigned.push(filterTask)
        }
      })
      return (isAssigned.length == selRespondents[1].length) ? true : false
    }
    return "empty";
  }

  const handleDone = () => {

    const filterTask = taskSet.filter(t => seltasks.includes(t.uuid));

    const columnTask = [
      {
        title: 'All Respondents',
        dataIndex: 'full_name',
        width: "600px",
      },
    ];

    console.log("Calling handleDone");

    filterTask.map(ft => {
      let tempTask = {
        title: (text, record, index) =>
          <React.Fragment>
            {
              (checkIfAllTaskAssigned(record, ft.uuid) == 'empty') ?
                <Space size="middle">
                  {/* <Button className='w-[130px]' type="primary" onClick={() => HandleUnassignTaskAll(record, ft.uuid)}>
                    { selRespondents[0].type == "all" ? 'Unassign All Now' : 'Unassign Selected' }
                  </Button> */}
                </Space>
                : (checkIfAllTaskAssigned(record, ft.uuid)) ?
                  <Space size="middle">
                    <Button className='w-[130px]' type="primary" onClick={() => { (projectRespondents.length == selRespondents[1].length) ? HandleUnassignTaskAll(record, ft.uuid) : HandleUnassignTaskSelected(record, ft.uuid)}} >
                      {projectRespondents.length == selRespondents[1].length ? 'Unassign All Now' : 'Unassign Selected'}
                    </Button>
                  </Space>
                  :
                  <Space size="middle">
                    <Button className='w-[130px] ' type="primary" onClick={() => { (projectRespondents.length == selRespondents[1].length) ? HandleAssignTaskAll(record, ft.uuid) : HandleAssignTaskSelected(record, ft.uuid) } }>
                      {projectRespondents.length == selRespondents[1].length ? 'Assign All Now' : 'Assign Selected'}
                    </Button>
                    <Popover content={
                      <div className=''>
                        <CustomDatePicker onChangeHandle={ (e, dateString, data) => { (projectRespondents.length == selRespondents[1].length)? onChangeHandle(e, dateString, data,'all') : onChangeHandle(e, dateString, data,'selected') }} data={ft.uuid} showTime className='!text-primary !border-solid !border !border-tertiary' format="YYYY-MM-DD HH:mm:ss" defaultValue={dayjs(new Date())} />
                      </div>
                    } title="Schedule at" placement="topRight" trigger="click">
                      <Button className='w-[130px] bg-neutral' type="secondary" onClick={() => selTaskDetails(ft.uuid)}>
                        {projectRespondents.length == selRespondents[1].length ? 'Schedule All Later' : 'Schedule Selected'}
                      </Button>
                    </Popover>
                  </Space>
            }
          </React.Fragment>
        ,
        dataIndex: ft.uuid,
        dataName: ft.title,
        key: '0',
        width: "300px",
        render: (text, record, index) =>
          <React.Fragment>
            {
              checkIfTaskExit(record, ft.uuid) ?
                <Space size="middle">
                  <CustomDatePicker disabled className='!text-primary !border-solid !border !border-tertiary' format="YYYY-MM-DD HH:mm:ss" defaultValue={dayjs(checkIfTaskExit(record, ft.uuid) ? checkIfTaskExit(record, ft.uuid)[0].scheduled_date : "")} />
                  <Button className='w-[130px] text-blue-800 underline ' type="Secondary" onClick={() => HandleUnssignTask(record, ft.uuid)}>
                    Unassign
                  </Button>
                </Space>
                :
                <Space size="middle">
                  <Button className='w-[130px]' type="primary" onClick={() => HandleAssignTask(record, ft.uuid)}>
                    Assign Now
                  </Button>
                  <Popover content={
                    <div className=''>
                      <CustomDatePicker onChangeHandle={onChangeHandleSingle} data={[ft.uuid, record]} showTime className='!text-primary !border-solid !border !border-tertiary' format="YYYY-MM-DD HH:mm:ss" defaultValue={dayjs(new Date())} />
                    </div>
                  } title="Schedule at" placement="topRight" trigger="click">
                    <Button className='w-[130px] bg-neutral' type="secondary" onClick={() => selTaskDetails(record, ft.uuid)}>
                      Schedule Later
                    </Button>
                  </Popover>
                </Space>
            }
          </React.Fragment>
      }
      columnTask.push(tempTask)
    })

    setColumnSet(columnTask);

    setOpen(false);
  }

  useEffect(() => {
    handleDone();
  }, [selRespondents])

  const onChangeHandle = (e, dateString, data, kind) => {
    const utcDate = moment.utc(moment(dateString).utc()).format();

    if (kind == "all") {
      HandleScheduleTaskAll(utcDate, data);
    } else {
      HandleScheduleTaskSelected(utcDate, data);
    }
    
  }

  const onChangeHandleSingle = (e, dateString, data) => {
    // HandleScheduleTask(dateString, taskDetails, data);
    const utcDate = moment.utc(moment(dateString).utc()).format();
    HandleScheduleTask(data[1], data[0], utcDate);
  }


  return (
    <React.Fragment>
      <Toolbar
        isAge
        isLocation
        isSegment
      />
      <div className='flex w-full justify-end mb-5 mt-5'>
        <div>
          <Button className='font-secondary bg-theme text-primary border-theme hover:!bg-theme  hover:!text-primary' onClick={() => { setOpen(true); setSelTaskSet([]); setSeltasks([]) }} >Select Theme</Button>
        </div>
      </div>

      {/* Custom table */}

      {/* <CustomTable tableData={userData} columns={columns} /> */}
      <div className='w-full flex justify-end text-left bg-secondary border-b border-secondary rounded-l-md rounded-r-md'>
        {
          columnSet.map(col =>
            (!(["full_name", "respondent_uuid", "initials"].includes(col.dataIndex))) ?
              <div className='w-[350px] p-4 text-base'>
                {col.dataName}
              </div>
              : null
          )
        }
      </div>
      <CustomTable className="" tableData={userData} columns={columnSet} onSelect={(rows, selections) => { setSelRespondents([rows, selections]) }} />

      <Drawer className='!bg-primary text-card' title="Select Theme" onClose={onClose} open={open}
        footer={<div className='flex space-x-4'>
          <Button className='w-1/2 font-secondary bg-theme text-primary border-theme hover:!bg-theme  hover:!text-primary' onClick={() => setOpen(true)} >Cancel</Button>
          <Button type="primary" className='w-1/2 font-secondary bg-theme text-primary border-theme hover:!bg-theme  hover:!text-primary' onClick={() => handleDone()} >Done</Button>
        </div>}
      >
        <div className='flex w-full flex-col'>
          <div className='text-sm font-light text-secondary'>You can select a minimum of 3 themes at a time.</div>
          <div className='flex w-full my-4'>
            <SearchBar onSearch={onSearch} className='w-full' />
          </div>
          <div className='flex flex-col w-full'>
            {
              tasks.map((task, index) =>
                <div className={`flex items-center space-x-2 pt-3 ${checkIfDisabled(task.uuid) ? ' blur-sm' : ''}`}>
                  <Checkbox style={{ color: 'var(--bg-secondary)' }} disabled={checkIfDisabled(task.uuid)} onChange={(e) => handleChange(e, task)} checked={seltaskSet[task.uuid]} />
                  <div className='text-base font-extralight'>{task.title}</div>
                </div>
              )
            }
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  )
}

export default AssignList
