import { AppstoreOutlined, CalendarOutlined, FlagOutlined, MessageOutlined, MoreOutlined, PaperClipOutlined } from '@ant-design/icons'
import { Avatar, Popover, Tooltip } from 'antd';
import CustomModalPopUp from 'components/Modal/customModal';
import CreateTask from 'pages/Tasks/create';
import EditTask from 'pages/Tasks/edit';
import ViewTaskModal from 'pages/Tasks/viewtaskModal';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { DELETEPROJECTTASKS } from 'state/actions';

function Card({ details, index = 1, isSubtask = false, noAction = false, w = "470px", h = "200px", wrapClass = "", min_h = "auto" }) {
    const dispatch = useDispatch();
    const [showDetails, setShowDetails] = useState(false);
    const [editDetails, setEditDetails] = useState(false);
    const [showOptions, setShowOptions] = useState(false);



    const deleteTask = (e) => {
        e.preventDefault();
        dispatch(DELETEPROJECTTASKS(details.uuid))
        setShowOptions(false)
    }

    const editTask = (e) => {
        e.preventDefault();
        // dispatch(DELETEPROJECTTASKS(details.uuid))
        setEditDetails(true);
        setShowOptions(false)
    }


    const content = (
        <div className='min-w-4 font-secondary'>
            <div className='p-2 -m-2 cursor-pointer' onClick={editTask}>Edit</div>
            <div className='p-2 -m-2 cursor-pointer' onClick={deleteTask}>Delete</div>
        </div>
    );


    return (
        <React.Fragment>
            <div className={`duration-500 transition-all flex rounded-2xl border border-tertiary ${(!noAction) ? '  hover:border-secondary hover:cursor-pointer ' : ''} bg-secondary w-[${w}] min-h-[${min_h}] h-[${h}] mr-2 ${wrapClass}`} onClick={() => (!isSubtask && !noAction) ? setShowDetails(true) : {}}>
                <div className='px-6 pt-6 pb-4 text-left space-y-3 w-full'>
                    <div className='flex justify-between'>
                        <div className='text-card text-base font-primary capitalize'>
                            {details?.title}
                        </div>
                        {
                            !noAction && (!(details?.respondent_names?.split(","))) ?
                                <div className='hover:bg-tertiary p-2 rounded-2xl' onClick={(e) => e.stopPropagation()}>
                                    {
                                        !isSubtask && (
                                            // <Popover open={showOptions} placement="bottomRight" title={""} content={content} trigger="click" >
                                            <Popover placement="bottomRight" title={""} content={content} trigger="click" className="!z-10" >
                                                <div className='flex justify-end cursor-pointer'>
                                                    <MoreOutlined />
                                                </div>
                                            </Popover>
                                        )
                                    }
                                </div> : null

                        }
                    </div>
                    {/* <div className='text-secondary text-xs overflow-hidden truncate w-[300px] line-clamp-3'> */}
                    <div className='text-secondary text-xs w-[300px] line-clamp-3 min-h-[48px]'>
                        {details?.description}
                    </div>
                    <div className='flex items-center justify-between pt-3'>
                        <div className='text-xs text-secondary'><FlagOutlined style={{ color: 'var(--text-secondary)' }} /> &nbsp; {new Date(details?.due_date).toDateString()} </div>
                        <Avatar.Group
                            size="small"
                            max={{
                                count: 4,
                                style: { color: 'var(--font-primary)', backgroundColor: 'var(--bg-tertiary)', fontSize: '12px' },
                            }}
                        >
                            {
                                details?.respondent_names?.split(",").map((user) => (
                                    <Tooltip title={user} placement="top">
                                        <Avatar className='bg-secondary border-2 ' src={"https://api.dicebear.com/7.x/miniavs/svg?seed=" + index} />
                                    </Tooltip>
                                ))
                            }
                        </Avatar.Group>
                    </div>
                    <div className='border-t border-tertiary pt-3 text-xs text-secondary justify-between flex items-center'>
                        <div className='flex items-center space-x-4'>
                            <div className=''>
                                {details?.subtasks?.length ?
                                    <div className='text-base'>
                                        <AppstoreOutlined style={{ color: 'text-[var(--color-secondary)]' }} />
                                        &nbsp;
                                        {details?.subtasks.length}
                                        {/* <PaperClipOutlined style={{ color: 'var(--text-secondary)' }} /> 2 */}
                                    </div>
                                    : null}
                            </div>
                            {/* <div>
                            <MessageOutlined style={{ color: 'var(--text-secondary)' }} /> 7
                        </div> */}
                        </div>
                        <div className='flex items-center'>
                            <CalendarOutlined style={{ color: 'var(--text-secondary)' }} />
                            &nbsp;
                            {new Date(details?.start_date).toDateString()}
                        </div>
                    </div>
                </div>
            </div>
            {showDetails &&
                <CustomModalPopUp
                    // title={details?.title + "  Activities"}
                    title={"Theme - " + details?.title}
                    okText="Back"
                    openPopup={showDetails}
                    onCancel={() => setShowDetails(false)}
                    width={'95%'}
                >
                    <ViewTaskModal detailsMain={details} details={details.subtasks} onCancel={() => setShowDetails(false)} />
                </CustomModalPopUp>
            }

            {editDetails &&
                <CustomModalPopUp
                    okText="Back"
                    openPopup={editDetails}
                    onCancel={() => setEditDetails(false)}
                    width={'95%'}
                >
                    <EditTask details={details} setEditDetails={setEditDetails} />
                </CustomModalPopUp>
            }

        </React.Fragment>
    )
}

export default Card
