import { MoreOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Popover } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import PopoverInfo from 'components/popoverInfo';
import TextInputNormal from 'components/TextInputNormal';
import UploadBox from 'components/Upload';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GETCLIENTPROJECTS, GETPROJECTTASKS, TASKUPDATEDSUCCESS, UPDATEPROJECTSTASK } from 'state/actions';
// import CreateSubTask from './createsubtask';
import CustomModalPopUp from 'components/Modal/customModal';
import dayjs from 'dayjs';
import moment from 'moment/moment';
import EditSubTask from './editSubtask';


const masterMedia = {
    Video: {
        defaultOption: "cameraVideo",
        value: "cameraVideo",
        isLandscapevideo: true,
        options: [
            {
                label: "Front or Back Camera",
                value: "cameraVideo",
            },
            {
                label: "Back Camera",
                value: "cameraVideoBack",
            },
            {
                label: "Front Camera",
                value: "cameraVideoFront",
            }
        ]
    },
    Gallery: {
        defaultOption: "galleryPhoto,galleryVideo",
        value: "gallery",
        isLandscapevideo: true,
        options: [
            {
                label: "Photo or Video any",
                value: "gallery",
            },
            {
                label: "Only Photo",
                value: "galleryPhoto",
            },
            {
                label: "Only Video",
                value: "galleryPhoto",
            }
        ]
    },
    Photo: {
        defaultOption: "cameraPhoto",
        value: "cameraPhoto",
        isLandscapevideo: true,
        options: [
            {
                label: "Front or Back Camera",
                value: "cameraPhoto",
            },
            {
                label: "Back Camera",
                value: "cameraPhotoBack",
            },
            {
                label: "Front Camera",
                value: "cameraPhotoFront",
            }
        ]
    }
}

function EditTask(props) {

    const { details } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selProject, updateTaskSuccess } = useSelector(state => state.user);

    const [masterMediaOptions, setMasterMediaOptions,] = useState(masterMedia);
    const [payload, setPayload,] = useState({
        "title": null,
        "start_date": new Date(),
        "due_date": new Date(),
        "description": null,
        "media_type": "cameraFront",
        "project_uuid": null,
        // "sample_media_link": null
    });
    const [subtask, setSubtask] = useState([]);
    const [selMedia, setSelMedia] = useState([]);
    const [isNewTask, setIsNewTask] = useState(false);
    const [subtaskData, setSubtaskData] = useState({});
    const [isEdit, setIsEdit] = useState(false);


    useEffect(() => {
        if (details?.title) {
            let payloadData = {
                "title": details.title,
                "start_date": details.start_date,
                "due_date": details.due_date,
                "description": details.description,
                "details": details.details,
                "media_type": details.media_type,
                "project_uuid": details.project_uuid,
                "task_uuid": details.uuid,
                "sample_media_link": details.sample_media_link || "",
                "task_instructions": details.task_instructions || "",
            }
            setPayload(payloadData)

            let payloadSubtaskData = [];
            details.subtasks.map(t => {
                let subdata = {
                    "title": t.title,
                    "start_date": t.start_date,
                    "due_date": t.due_date,
                    "description": t.description,
                    "details": t.details,
                    "media_type": t.media_type,
                    "subtask_uuid": t.uuid
                }
                payloadSubtaskData.push(subdata);
            })
            setSubtask(payloadSubtaskData)
            // setSubtask(details.subtasks)
        }
    },[details])

    const onChangeHandle = (fileName, status) => {
        setPayload({ ...payload, sample_media_link: fileName })

    }

    const onChange = (e) => {
        const { value, name } = e.target;
        setPayload({ ...payload, [name]: value })
    }

    const onSubmit = (e) => {
        let payloadData = {};
        const values = [];
        selMedia.map(media => {
            values.push(masterMediaOptions[media].value);
        })
        let subtasks = [];
        // const subtaskData = subtask.filter(task => { delete task.slug ; delete task.sample_media_link; delete task.status; delete task.task_instructions; delete task. });
        subtask.map(task => {
            let TaskData = task;
            delete task.slug; 
            delete task.sample_media_link;
            delete task.status; 
            delete task.task_instructions; 
            delete task.createdAt;
            delete task.updatedAt;
            delete task.uuid;
            delete task.audio_comment;
            TaskData = task;
            // TaskData.subtask_uuid = task.uuid
            subtasks.push(TaskData)
        });

        if (payload.task_instructions == "") {
            delete payload.task_instructions
        }

        if (payload.sample_media_link == "") {
            delete payload.sample_media_link
        }

        payloadData = { ...payload, subtasks: subtasks };
        dispatch(UPDATEPROJECTSTASK(payloadData))
    }

    useEffect(() => {
        if (updateTaskSuccess) {
            dispatch(TASKUPDATEDSUCCESS(false))
            props.setEditDetails(false)
            setTimeout(() => {
                dispatch(GETPROJECTTASKS(selProject.uuid)) 
            }, 2000);
        }
    }, [updateTaskSuccess])


    const handleDateDeadline = (date, dateString) => {
        setPayload({ ...payload, start_date: dateString[0], due_date: dateString[1] })
    }

    const handleAddTask = (taskDetails) => {
        setSubtask([...subtask, taskDetails])
        setIsNewTask(false)
        setIsEdit(false)
    }

    const handleUpdateTask = (taskDetails) => {
        const taskData = subtask.filter(task => !(task.slug == taskDetails.slug));
        setSubtask([...taskData, taskDetails])
        setIsNewTask(false)
        setIsEdit(false)
    }

    const deleteSubtask = (taskDetails) => {
        const taskData = subtask.filter(task => !(task.slug == taskDetails.slug));
        setSubtask(taskData)
    }

    const checkIfValid = () => {
       let isValid = false;
        Object.entries(payload).map(load => {
            if(!load[1]) {
                if (load[0] !== "project_uuid" && load[0] !== "sample_media_link" && load[0] != "task_instructions" && subtask.length > 0) {
                    isValid = true
                } else if (!subtask.length) {
                    isValid = true
                } 
            } 
        })
        return isValid
    }

    const opentaskPopup = (status, isEdit, editData={}) => {
        if (isEdit) {
            setIsEdit(true)
            setSubtaskData(editData)
        }
        setIsNewTask(status)
    }

    useEffect(() => {
        dispatch(GETCLIENTPROJECTS())
    }, [])

    const getMediaName = (media_type) => {
        const mediaOptions = (media_type).replace("camera", "").replace("Back", "").replace("Front", "")
        return mediaOptions
    }

    return (
        <div className='flex flex-col'>
            <div className='flex justify-center flex-col items-center'>
                <div className='text-2xl font-primary font-semibold'>Edit Activity</div>
                <div className='flex flex-col w-1/2 mt-8 space-y-6'>
                    <div className='flex w-full items-center'>
                        <TextInputNormal name="title" className="w-96" size="large" label="Task Name" placeholder="Enter your task name" onChange={onChange} value={payload.title} />
                        <PopoverInfo content={<div>Provide a concise and descriptive title for the task. It should clearly convey the main objective or purpose of the task.</div>} />
                    </div>
                    <div className='flex w-full items-center'>
                        <TextInputNormal name="details" className="w-96" size="large" label="One Line Description"
                            placeholder="Describe in a line about your task" onChange={onChange} value={payload.details} />
                        <PopoverInfo content={<div>Provide a concise and descriptive title for the task. It should clearly convey the main objective or purpose of the task.</div>} />
                    </div>
                    <div className='flex w-full items-center'>
                        <TextInputNormal
                            name="description"
                            children={
                                <TextArea
                                    name="description"
                                    className='w-96 mt-3'
                                    placeholder="Enter task description"
                                    autoSize={{
                                        minRows: 4,
                                        maxRows: 7,
                                    }}
                                    value={payload.description}
                                    onChange={onChange}
                                />
                            }
                            label="Description"
                            
                            onChange={onChange} />
                        <PopoverInfo content={<div>Give a brief but detailed overview of the task, including the key activities, expectations, and any relevant instructions. Aim for clarity and completeness to ensure all participants understand the task.</div>} />
                    </div>
                    <div className='flex w-full items-center'>
                        <TextInputNormal
                            name="task-dates"
                            children={
                                <div className='flex w-96 justify-between mt-3'>
                                    <DatePicker.RangePicker size="large" className='!w-96 text-secondary' showTime onChange={handleDateDeadline}
                                        // defaultValue={[dayjs(new Date()), dayjs(new Date()).add("7", 'days')]}
                                        minDate={dayjs(new Date())}
                                        value={[dayjs(payload.start_date), dayjs(payload.due_date)]}
                                    />
                                    {/* <CustomDatePicker size="large" className='!w-96 text-secondary'  /> */}
                                </div>
                            }
                            label="Task Deadline"
                            onChange={onChange} />
                        <PopoverInfo content={<div>Specify the final date and time by which the task must be completed. Use a clear format (e.g., DD/MM/YYYY, HH ,AM/PM) to avoid confusion.</div>} />
                    </div>
                    <div className='flex w-full items-center'>
                        <TextInputNormal
                            name="sample_media_link"
                            children={
                                <UploadBox className=" !w-96 mt-3" imageUrl={payload.sample_media_link} handleChange={onChangeHandle} />
                            }
                            label="Intro video"
                            value={payload.sample_media_link}
                            onChange={onChange} />
                        <PopoverInfo content={<div>Specify the final date and time by which the task must be completed. Use a clear format (e.g., DD/MM/YYYY, HH ,AM/PM) to avoid confusion.</div>} />
                    </div>

                    <div className='flex w-full items-center'>
                        <TextInputNormal
                            children={
                                <TextArea
                                    name="task_instructions"
                                    className='w-96 mt-3'
                                    placeholder="Enter announcement"
                                    autoSize={{
                                        minRows: 4,
                                        maxRows: 7,
                                    }}
                                    onChange={onChange}
                                    value={payload.task_instructions}
                                />
                            }
                            label="Add Announcement"
                            onChange={onChange} />
                        <PopoverInfo content={<div>Dekko automatically sends this initial message (via email and push notification) to participants when their assignment is published. You might want to use something generic like "You've got a new task" or create something more personalized. Leave this field blank to skip sending a notification, but note that without it, participants won’t know there’s a new assignment until they check the app. Typically, the first task is left blank since participants will receive a project invitation email when their first assignment is published.</div>} />
                    </div>

                    <div className='flex w-full items-start flex-col'>
                        <div className='text-base'>Subtask</div>
                        {
                            subtask.map(taskDetails => (
                                <div className='subtask-wrap relative  mt-6 '>
                                    <Popover placement="topLeft" title={""}
                                        content={
                                            <div className='min-w-1 font-secondary'>
                                                <div className='p-2 -m-2 cursor-pointer text-xs' onClick={() => opentaskPopup(true, true, taskDetails)}>Edit</div>
                                                <div className='p-2 -m-2 cursor-pointer text-xs' onClick={() => deleteSubtask(taskDetails)}>Delete</div>
                                            </div>
                                        }
                                        trigger="click">
                                        <div className='flex justify-end cursor-pointer absolute top-3 right-3'>
                                            <MoreOutlined />
                                        </div>
                                    </Popover>

                                    <div className='ribbon text-xs'>
                                        {/* { taskDetails.media_type == "cameraPhoto" ?  "Photo" : "Video" } */}
                                        { getMediaName(taskDetails.media_type) }
                                    </div>
                                    <div className='w-96 p-4 bg-tertiary rounded-xl' onClick={() => opentaskPopup(true, true, taskDetails)} >
                                        <div className=' space-y-1 text-left pt-4'>
                                            <div className='text-secondary text-xs'>{moment(taskDetails.due_date).format("DD MMMM YYYY, HH:MMA")}</div>
                                            <div className='text-sm'>{taskDetails.title}</div>
                                            <div className='text-secondary text-xs'>{taskDetails.details}</div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className='flex w-full items-center'>
                        <div className='p-4 w-96 border-dashed border-secondary border rounded-xl cursor-pointer' onClick={() => opentaskPopup(true, false )}>
                        {/* <div className='p-4 w-96 border-dashed border-secondary border rounded-xl cursor-pointer' onClick={() => setIsNewTask(true)}> */}
                            <div className='text-secondary text-base'>+ Add more subtask</div>
                        </div>
                        <PopoverInfo content={<div>Indicate the format in which the task response should be submitted (e.g., text, video, image, audio, screen recording). Choose the most appropriate media type for the task's requirements.</div>} />
                    </div>

                    <div className='flex items-center w-1/2 justify-between'>
                        {/* <Button className='bg-secondary text-white hover:bg-secondary w-32' onClick={() => navigate("/dashboard")}>Cancel </Button> */}
                        <Button className='bg-secondary text-white hover:bg-secondary w-32' onClick={() => props.setEditDetails(false)}>Cancel </Button>
                        <Button type="primary" disabled={checkIfValid()} onClick={onSubmit}>Update Task</Button>
                    </div>
                </div>
            </div>
            {
                isNewTask && (
                    <CustomModalPopUp
                        title="Create Activity"
                        okText="Create Activity"
                        openPopup={isNewTask}
                        onCancel={() => setIsNewTask(false)}
                        style={{ top : 20 } }
                        maskClosable={false}
                    >
                        <EditSubTask  subtaskData={subtaskData} payload={payload} setSubtaskData={setSubtaskData} handleAddTask={isEdit ? handleUpdateTask : handleAddTask} onCancel={() =>opentaskPopup(false, false )} />
                    </CustomModalPopUp>
                )
            }
        </div>
    )
}

export default EditTask
