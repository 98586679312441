import { CheckCircleOutlined, EyeOutlined, HistoryOutlined, MessageOutlined, RightOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Avatar, Button, Tooltip } from 'antd';
import GaugeChart from 'components/Charts/gauge';
import Image from 'components/image';
import UniversalMediaCard from 'components/image/UniversalMediaCard';
import moment from 'moment';
// import Toolbar from 'components/Toolbar';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GETOVERVIEW, GETPROJECTOVERVIEW } from 'state/actions';

function ProjectOverview(props) {


  const dispatch = useDispatch();
  const { selProject, userDetails } = useSelector(state => state.user)
  const { taskscount, overviewResponse, taskUpdated } = useSelector(state => state.task)
  const [taskCountStat, setTaskCountStat] = useState([])
  const [taskList, setTaskList] = useState([])
  const [progressPer, setProgressPer] = useState("")

  useEffect(() => {
    if (selProject?.uuid) {
      const payload = {
        project_uuid: selProject.uuid,
        page: 1,
        pageSize: 10,
        // task_status: 'approved'
        task_status: 'in_review'
      }
      dispatch(GETPROJECTOVERVIEW(payload))
      dispatch(GETOVERVIEW(selProject?.uuid))
    }
  }, [selProject])

  useEffect(() => {
    if (taskUpdated) {
      const payload = {
        project_uuid: selProject.uuid,
        page: 1,
        pageSize: 10,
        // task_status: 'approved'
        task_status: 'in_review'
      }
      dispatch(GETPROJECTOVERVIEW(payload))
      dispatch(GETOVERVIEW(selProject?.uuid))
    }
  }, [taskUpdated])

  const calcPercentage = (count, outof) => {
    const per = count / outof * 100;
    if (per > 0) return +(Math.round(per + "e+2") + "e-2") + "%";
    return "-"
  }

  useEffect(() => {
    if (taskscount) {
      const allCount = (taskscount?.total_Approved + taskscount?.total_Rejected + taskscount?.total_redo + taskscount?.total_in_Review);
      setTaskCountStat([taskscount?.total_Approved, taskscount?.total_redo || 0, taskscount?.total_in_Review, (taskscount?.total_tasks - allCount)])
      const taskListMain = [
        {
          key: 'total_tasks',
          label: "All Responses",
          count: (taskscount?.total_Approved + taskscount?.total_Rejected + taskscount?.total_redo + taskscount?.total_in_Review) || 0,
          percentage: calcPercentage((taskscount?.total_Approved + taskscount?.total_Rejected + taskscount?.total_redo + taskscount?.total_in_Review), taskscount?.total_tasks),
          colorCode: '#1355FF',
          class: 'border-[#1355FF]',
          icon: <CheckCircleOutlined className='!text-[#1355FF]' />
        },
        {
          key: 'total_Approved',
          label: "Approved",
          count: taskscount?.total_Approved || 0,
          percentage: calcPercentage(taskscount?.total_Approved, taskscount?.total_tasks),
          colorCode: '#12A58C',
          class: 'border-[#12A58C]',
          icon: <CheckCircleOutlined className='!text-[#12A58C]' />
        },
        {
          key: 'total_in_Review',
          label: "In-Review",
          count: taskscount?.total_in_Review || 0,
          percentage: calcPercentage(taskscount?.total_in_Review, taskscount?.total_tasks),
          colorCode: '#FFD234',
          class: 'border-[#FFD234]',
          icon: <EyeOutlined className='!text-[#FFD234]' />
        },
        {
          key: 'total_Rejected',
          label: "Redo",
          count: taskscount?.total_redo || 0,
          percentage: calcPercentage(taskscount?.total_redo, taskscount?.total_tasks),
          colorCode: '#F6285F',
          class: 'border-[#F6285F]',
          icon: <HistoryOutlined className='!text-[#F6285F]' />
        }
      ]
      const projectProgress = ((taskscount?.total_Approved)/  taskscount?.total_tasks ) * 100
      setProgressPer(+(Math.round(projectProgress + "e+2") + "e-2"))
      setTaskList(taskListMain)

    }
  }, [taskscount])


  return (
    <React.Fragment>
      <div className='w-full'>

        <div className='flex items-center justify-between my-[30px]'>
          <div className='flex flex-col text-left space-y-2'>
            <div className='text-xl font-primary font-bold'>Good Morning, {userDetails.first_name || "User"} {userDetails.last_name || (userDetails?.uid)?.substring(1, 8) }</div>
            <div className='text-sm text-secondary'>Here is an overview of your recent project activity</div>
          </div>
          <div>
            <Button className='w-full text-primary' color='default' variant="outlined">
              Project Preview 👀
            </Button>
          </div>
        </div>

        <div className='w-full flex space-x-8'>
          <div className='w-[77%] text-left'>
            <div className='bg-secondary p-10 rounded-lg space-y-10 mb-10'>
              <div className='text-xl font-primary font-bold'>Project Progress</div>
              <div className='w-full flex items-center justify-between'>
                <div className=''>
                  <div className={``}>
                    <GaugeChart data={taskCountStat} width={200} label={<div className='flex flex-col items-center'><div className='text-xl font-primary font-bold'>{progressPer ? progressPer + "%" : "" }</div><div className='text-sm text-secondary w-1/2'>Project Completed</div></div>} />
                    {/* <GaugeChart data={taskCountStat} width={200} label={<div className='flex flex-col mt-2'><div className='text-sm'>Project <br /> Progress</div></div>} /> */}
                  </div>
                </div>
                <div className='flex space-x-5'>
                  {taskList.map(task => (
                    <div className={`bg-tertiary p-4 flex flex-col space-y-5 w-[180px] rounded-2xl border-b ` + task.class}>
                      <div className='flex justify-between items-center'>
                        <div className='text-sm text-gray-300'>{task.label}</div>
                        {task.icon}
                      </div>
                      <div className='flex justify-between items-center'>
                        <div className='text-2xl font-bold font-primary'>{task.count}</div>
                        <div className='text-xs text-gray-300'>{task.percentage}</div>
                      </div>
                    </div>
                  ))
                  }
                </div>
              </div>
            </div>

            <div className='bg-secondary p-10 rounded-lg '>
              <div className='flex items-center justify-between'>
                <div className='text-xl font-primary font-bold'>Recent Entries</div>
                <div className='text-xl font-primary font-bold underline cursor-pointer' onClick={props.setSelectedTab('overview')} >View All</div>
              </div>
              <div className='mt-5 flex flex-wrap gap-x-5'>
                {
                  overviewResponse.length ?
                  overviewResponse.map(user => (
                    <React.Fragment>
                      <div className='relative group'>
                        <UniversalMediaCard url={(user.media_link).split(",")[0]} wrapperclassName={"w-[235px] h-[235px] mt-6 rounded-xl"} className="rounded-lg w-[235px] h-[235px] object-cover" project={selProject} details={user} />
                        <div className='absolute bottom-0 w-full p-4 image-card-bottom transition-all duration-1000 ease-in-out group-hover:h-[235px] group-hover:rounded-t-xl group-hover:flex group-hover:flex-wrap group-hover:content-between'>
                        {/* <div className='absolute bottom-0 w-full p-4 image-card-bottom transition-all duration-1000 ease-in-out group-hover:h-[231px] group-hover:rounded-lg group-hover:flex group-hover:flex-wrap group-hover:content-between'> */}
                          <div className='text-base hidden group-hover:flex capitalize'>{user.task_title}</div>
                          <div className='flex items-center justify-between w-full'>
                            <div className='flex flex-col text-left'>
                              <div className='text-sm capitalize'>{user.respondent_name}</div>
                              <div className='text-xs text-gray-200'>{moment(user.submitted_date).format("DD MMM YYYY")}</div>
                            </div>
                            <div className=''>
                              <VideoCameraOutlined />
                            </div>
                          </div>
                        </div>
                      </div>

                    </React.Fragment>
                  ))
                  : 
                  <div className='text-base text-secondary'>No recent response submitted for review.</div>
                }
              </div>
            </div>
          </div>
          <div className='w-[23%] text-left'>
            <div className='p-10 bg-secondary w-full rounded-xl'>
              <div className='text-xl font-primary font-bold'>Project Settings</div>
              <div className='pt-10'>
                <div className='flex items-center space-x-4 border-b pb-4 pt-4 border-tertiary'>
                  <div><Image url="admins.svg" /> </div>
                  <div className='flex items-center justify-between w-full'>
                    <div className='text-base'>Admins ({taskscount?.total_ProjectAdmins || 0})</div>
                    <RightOutlined />
                  </div>
                </div>
                <div className='flex items-center space-x-4 border-b pb-4 pt-4 border-tertiary'>
                  <div><Image url="viewers.svg" /></div>
                  <div className='flex items-center justify-between w-full'>
                    <div className='text-base'>Viewers ({taskscount?.total_Viewers || 0})</div>
                    <RightOutlined />
                  </div>
                </div>
                <div className='flex items-center space-x-4 border-b pb-4 pt-4 border-tertiary'>
                  <div><Image url="respondent.svg" /> </div>
                  <div className='flex items-center justify-between w-full'>
                    <div className='text-base'>Respondents ({taskscount?.total_Respondents || 0})</div>
                    <RightOutlined />
                  </div>
                </div>
                <div className='flex items-center space-x-4 border-b pb-4 pt-4 border-tertiary'>
                  <div><Image url="edit-project.svg" /> </div>
                  <div className='flex items-center justify-between w-full'>
                    <div className='text-base'>Edit Project Details</div>
                    <RightOutlined />
                  </div>
                </div>
                <div className='flex items-center space-x-4 pb-4 pt-4'>
                  <div><Image url="trash.svg" /></div>
                  <div className='flex items-center justify-between w-full'>
                    <div className='text-base'>Close Project</div>
                    <RightOutlined />
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-10 p-10 bg-secondary w-full rounded-xl'>
              <div className='text-xl font-primary font-bold'>Recent Activity</div>
              <div className='text-sm text-gray-300'>You have 34 messages/comments</div>
              <div className='pt-5 space-y-7'>
                <div className='flex items-center'>
                  {/* <Image url="profile.png" /> */}
                  <Avatar size="md" shape="circle" src={"https://avatar.iran.liara.run/public/boy?username="+ (userDetails.first_name || "User")} />
                  <div className='flex flex-col text-left px-3 w-[190px]'>
                    <div className='text-sm'>Ayush Khanna</div>
                    <Tooltip title={"Kapil has commented on your activity."} placement="top">
                    <div className='text-sm text-secondary text-ellipsis truncate'>Kapil has commented on your activity.</div>
                    </Tooltip>
                  </div>
                  <MessageOutlined />
                </div>
                <div className='flex items-center'>
                  {/* <Image url="profile.png" /> */}
                  <Avatar size="md" shape="circle" src={"https://avatar.iran.liara.run/public/boy?username="+ (userDetails.first_name || "User")} />
                  <div className='flex flex-col text-left px-3 w-[190px]'>
                    <div className='text-sm'>Avinash Singh</div>
                    <Tooltip title={"Your activity is approved."} placement="top">
                    <div className='text-sm text-secondary text-ellipsis truncate'>Your activity is approved.</div>
                    </Tooltip>
                  </div>
                  <MessageOutlined />
                </div>
                <div className='flex items-center'>
                  {/* <Image url="profile.png" /> */}
                  <Avatar size="md" shape="circle" src={"https://avatar.iran.liara.run/public/boy?username="+ (userDetails.first_name || "User")} />
                  <div className='flex flex-col text-left px-3 w-[190px]'>
                    <div className='text-sm'>Neha Kumar</div>
                    <Tooltip title={"Your activity is rejected, Please check."} placement="top">
                    <div className='text-sm text-secondary text-ellipsis truncate'>Your activity is rejected, Please check</div>
                    </Tooltip>
                  </div>
                  <MessageOutlined />
                </div>
                <div className='flex items-center'>
                  {/* <Image url="profile.png" /> */}
                  <Avatar size="md" shape="circle" src={"https://avatar.iran.liara.run/public/boy?username="+ (userDetails.first_name || "User")} />
                  <div className='flex flex-col text-left px-3 w-[190px]'>
                    <div className='text-sm'>Amit Dev</div>
                    <Tooltip title={"Kapil has commented on your activity."} placement="top">
                    <div className='text-sm text-secondary text-ellipsis truncate'>Kapil has commented on your activity.</div>
                    </Tooltip>
                  </div>
                  <MessageOutlined />
                </div>
                <div className='flex items-center'>
                  {/* <Image url="profile.png" /> */}
                  <Avatar size="md" shape="circle" src={"https://avatar.iran.liara.run/public/boy?username="+ (userDetails.first_name || "User")} />
                  <div className='flex flex-col text-left px-3 w-[190px]'>
                    <div className='text-sm'>Rashmi Tyagi</div>
                    <Tooltip title={"Kapil has commented on your activity."} placement="top">
                    <div className='text-sm text-secondary text-ellipsis truncate'>Kapil has commented on your activity.</div>
                    </Tooltip>
                  </div>
                  <MessageOutlined />
                </div>
              </div>
              <Button className='mt-6 w-full border-secondary !text-primary' variant="outlined" >View All</Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProjectOverview
