import Card from 'components/Cards'
import CustomTable from 'components/Table';
import Toolbar from 'components/Toolbar'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DELETEPROJECTTASKSUCCESS, GETPROJECTTASKS } from 'state/actions';


function Task() {

  
  const columns = [
    {
      title: 'Task',
      dataIndex: 'title',
      // render: (text) => <a>{text}</a>,
    },  
    {
      title: 'Create Date',
      dataIndex: 'start_date',
      render: (text) => <a>{new Date(text).toDateString()}</a>,
    },  
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      render: (text) => <a>{new Date(text).toDateString()}</a>,
    },  
  ]


  const dispatch = useDispatch();
  const { selProject } = useSelector(state => state.user)
  const { tasks, isTaskDeleted } = useSelector(state => state.task)
  const [taskSet, setTaskSet] = useState([]);
  const [viewName, setViewName] = useState('grid');

  useEffect(() => {
    if (selProject?.uuid) {
      dispatch(GETPROJECTTASKS(selProject.uuid))
    }
  },[selProject])

  useEffect(() => {
    setTaskSet(tasks);
  },[tasks])

  useEffect(() => {
    if (isTaskDeleted) {
        dispatch(DELETEPROJECTTASKSUCCESS(false))
        dispatch(GETPROJECTTASKS(selProject.uuid))
    }
},[isTaskDeleted])

const handleViewChange = (view) => {
  setViewName(view);
}

  return (
    <React.Fragment>
      {/* <Toolbar  isSortStatus isDate viewType viewName={viewName} handleViewChange={handleViewChange} /> */}
      <Toolbar  isSortStatus isDate handleViewChange={handleViewChange} />
      <div className='flex py-2 flex-wrap space-y-3 items-baseline w-full'>
        {
          taskSet.length ?(
          viewName == "grid" ?
          taskSet.map((card, index) =>
            <Card details={card} h="auto" min_h={'48px'} />
          )
          :
          <CustomTable className="w-full" columns={columns} tableData={taskSet} />
        )
          :
          <div className='flex justify-center w-full mt-5'>No task found..!!</div>
        }
      </div>
    </React.Fragment>
  )
}

export default Task
