import CustomTable from 'components/Table'
import Toolbar from 'components/Toolbar'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GETPROJECTRESPONDENTS } from 'state/actions';

function Members() {

  const dispatch = useDispatch();
  const {projectRespondents} = useSelector(state => state.task);
  const { selProject } = useSelector(state => state.user)
  const [userData, setUserData] = useState([]);
  const [filtrersData, setFiltrersData] = useState({});


  useEffect(()=>{
    setUserData(projectRespondents)
  },[projectRespondents])

  useEffect(()=>{
    if (selProject?.uuid) {
      dispatch(GETPROJECTRESPONDENTS({project_uuid : selProject.uuid }))
    }
  },[selProject])

  return (
    <React.Fragment>
      {/* <Toolbar isStatus setFiltrersData={setFiltrersData} /> */}
      <CustomTable tableData={userData} className="pt-4" />
    </React.Fragment>
  )
}

export default Members
