import { setCookie } from "utils/session";

const initialState = {
  isLoading: false,
  notify: {
    type: "",
    message : "",
    timeStamp: ""
  },
  userData: {
    firstName: null,
    lastName: null,
    email: null,
    name: null,
    code: null,
    mobile: null,
    userName: null,
    profileImage: null,
    referalCode: "",
  },
  userDetails: {},
  userauth : {
    token: "",
    expiry: ""
  },
  isLoggedIn: false,
  userOTPPayload : [],
  selProject: {},
  createTaskSuccess: false,
  updateTaskSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_NOTIFICATION": {
      return {
        ...state,
        notify: { ...payload, timeStamp : new Date().getTime() },
      };
    }
    case "SET_LOADING": {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case "REMOVE_USER_AUTH": {
      setCookie("_u", "", 3)
      return {
        ...state,
        userauth: '',
        isLoggedIn: false,
      };
    }
    case "SET_USER_AUTH": {
      setCookie("_u", payload.token.token, 3)
      // document.cookie = `_u=${payload.token.token};expires=${payload.token.expires}`;
      return {
        ...state,
        userauth: payload.token,
        isLoggedIn: !!payload.token,
      };
    }
    case "SET_USER_DETAILS": {
      return {
        ...state,
        userDetails: payload,
      };
    }
    case "SET_USER_DATA": {
      return {
        ...state,
        userData: payload,
      };
    }
    case "SET_MOBILE_OTP": {
      return {
        ...state,
        userOTPPayload: { sid: true},
        // userOTPPayload: payload,
      };
    }
    case "RESET_MOBILE_OTP": {
      return {
        ...state,
        // userOTPPayload: { sid: true},
        userOTPPayload: payload,
      };
    }
    case "SET_CLIENT_PROJECT": {
      return {
        ...state,
        selProject: payload.length  == 1 ? payload[0] : payload ,
      };
    }

    case "CREATE_PROJECT_TASK_SUCCESS": {
      return {
        ...state,
        createTaskSuccess: payload,
      };
    }

    case "UPDATE_PROJECT_TASK_SUCCESS": {
      return {
        ...state,
        updateTaskSuccess: payload,
      };
    }

    default:
      return state;
  }
};
