import { AppstoreOutlined, FlagOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import Card from 'components/Cards'
import UniversalMediaCard from 'components/image/UniversalMediaCard';
import moment from 'moment';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';

function ViewTaskModal({ details = [], detailsMain = [] }) {

    const [taskDetails, setTaskDetails] = useState({});
    const { selProject } = useSelector(state => state.user)

    return (
        <div className='w-full flex items-start pt-3'>
            <div className='w-[70%]'>
                <div className='w-full flex flex-col '>
                    {/* <Card details={detailsMain} wrapClass='mb-3' w={"50%"} h="auto" min_h={'48px'} noAction /> */}
                    <Card details={detailsMain} w={"50%"} h="auto" min_h={'48px'} noAction />

                    <div className='w-[370px] flex justify-center'>
                        <div className='border-l-2 pb-7 border-secondary'></div>
                    </div>
                    {
                        details.length > 1 && (
                            <div className={`w-[400px] ml-[20%] border-b-2 border-secondary`}></div>
                        )
                    }

                    {details.length &&
                        (
                            <div className='flex flex-wrap'>
                                {
                                    details.map(task => (
                                        <div onClick={() => setTaskDetails(task)}>
                                            <div className='w-[370px] flex justify-center'>
                                                <div className={'border-l-2 pb-4 border-secondary '}></div>
                                            </div>
                                            <Card details={task} w={"370px"} h="auto" isSubtask  wrapClass={(task.uuid == taskDetails.uuid ? " !border-primary" : " border-secondary")}  />
                                            {/* <Card details={task} w={"370px"} h="auto" wrapClass="mt-2" isSubtask /> */}
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
            </div>
            <div className='w-[30%]'>
                {
                    taskDetails?.title ?
                        <div className='w-full flex p-2 bg-secondary rounded-xl min-h-[60vh]'>
                            <div className='flex flex-col items-start relative w-full'>
                                <div className=' flex w-full p-1 absolute top-1 right-1 justify-end'>
                                    <div className='flex px-2 py-1 rounded-xl bg-secondary'>
                                        <div className='font-secondary text-xs'>{taskDetails.media_type}</div>
                                        {/* <div className='font-secondary text-xs'>{moment(taskDetails.createdAt).fromNow()}</div> */}
                                    </div>
                                </div>
                                <div className='px-1 py-1 flex justify-between w-full items-baseline'>
                                    <div className='flex flex-col text-left space-y-1 w-full'>
                                        <div className='flex justify-center items-center p-4 rounded-lg bg-tertiary min-h-[10rem]'>
                                            <Skeleton.Image active={true} size="large" shape="rounded" />
                                        </div>
                                        <div className='py-4 space-y-2 px-2'>
                                            <div className='font-secondary text-base font-bold capitalize'>{taskDetails.title}</div>
                                            <div className='font-secondary text-tertiaryfont text-sm'>{taskDetails.details}</div>
                                            <div className='border-t border-secondary'></div>
                                            <div className='text-xs space-x-1'>
                                                <span className='text-xs space-x-1'><FlagOutlined />{" "}Deadline: </span>
                                                <span className='text-secondary text-xs space-x-1'>{taskDetails.due_date}</span>
                                                <span className='text-secondary text-xs space-x-1'>{"  "}(Expiring {moment(taskDetails.due_date).fromNow()})</span>
                                            </div>
                                            <div className='font-secondary text-tertiaryfont text-sm'>{taskDetails.description}</div>
                                            <div className=''></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='text-secondary rounded-xl bg-secondary p-4 text-center min-h-[60vh]'>
                            Task Preview
                        </div>
                }
            </div>
        </div>
    )
}

export default ViewTaskModal
